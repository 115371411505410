export const releaseNotesDe: ReleaseNotes = [
    {
        release: "0.2.0",
        date: "2024-09-12",
        tags: [
            "Meilienstein 2", "Stammdaten"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 2"
            },
            {
                "description": "Nutzerverwaltung erweitert"
            },
            {
                "description": "Kategorien implementiert"
            },
            {
                "description": "Kostenstellen implementiert"
            },
            {
                "description": "Standardgeräte implementiert"
            },
            {
                "description": "Mailvorlagen vorbereitet"
            }
        ]
    },
    {
        release: "0.1.0",
        date: "2024-07-31",
        tags: [
            "Meilienstein 1", "Grundsystem"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 1"
            },
            {
                "description": "Fertigstellung des Grundsystems"
            },
            {
                "description": "Nutzerverwaltung implementiert"
            },
            {
                "description": "Berechtigungssystem vorbereitet"
            }
        ]
    },
    {
        release: "0.0.0",
        date: "2024-07-01",
        tags: [
            "Projektstart"
        ],
        type: "major",
        notes: [
            {
                "description": "Das Projekt wurde aufgesetzt. Die Entwicklung kann beginnen!"
            }
        ]
    }
]