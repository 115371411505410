import {useLayoutStore} from "../store/layout";

export const $getCurrentModule = () => {
    const module = useLayoutStore().currentModule
    return module ? appModules[module] : null
}

export const $getModuleIcon = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.icon ?? null
    return appModules[module]?.icon ?? null
}

export const $getModuleColor = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.color ?? null
    return appModules[module]?.color ?? null
}

export const appModules: AppModules = {
    dashboard: {
        name: 'dashboard',
        icon: 'heroicons:home'
    },
    orders: {
        name: 'users',
        icon: 'heroicons:shopping-bag',
        color: 'primary',
    },
    users: {
        name: 'users',
        icon: 'heroicons:users',
        color: 'primary',
        search: {
            detail: 'users-id-userBaseData',
            nameProp: 'fullname'
        },
    },
    organisations: {
        name: 'organisations',
        icon: 'heroicons:truck',
        color: 'primary',
        search: {
            detail: 'organisations-id-organisationBaseData'
        },
    },
    settings: {
        name: 'settings',
        icon: 'heroicons:cog-6-tooth',
    },
    baseData: {
        name: 'settings',
        icon: 'heroicons:wrench-screwdriver',
    },
    categories: {
        name: 'categories',
        icon: 'heroicons:tag',
        search: {
            listing: 'baseData-categories',
            detail: 'baseData-categories-id',
        },
    },
    devices: {
        name: 'devices',
        icon: 'heroicons:device-phone-mobile',
        search: {
            listing: 'baseData-devices',
            detail: 'baseData-devices-id',
        },
    },
    costCenters: {
        name: 'costCenters',
        icon: 'heroicons:banknotes',
        search: {
            listing: 'baseData-costCenters',
            detail: 'baseData-costCenters-id',
        },
    },
}
