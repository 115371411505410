import {$getModuleColor, $getModuleIcon} from "./appModules";

export const sidebarMenu: SidebarMenu = [
    {
        title: 'default',
        menu: [
            {
                title: 'menu.dashboard',
                path: '/',
                icon: $getModuleIcon('dashboard'),
                color: $getModuleColor('dashboard'),
            },
            {
                title: 'menu.orders',
                path: 'orders',
                icon: $getModuleIcon('orders'),
                color: $getModuleColor('orders')
            },
            {
                title: 'menu.organisations',
                path: 'organisations',
                ability: ['update', 'organisations'],
                icon: $getModuleIcon('organisations'),
                color: $getModuleColor('organisations')
            },
            {
                title: 'menu.settings',
                path: 'settings',
                icon: $getModuleIcon('settings'),
                color: $getModuleColor('settings'),
                children: [
                    {
                        title: 'menu.users',
                        path: 'users',
                        icon: $getModuleIcon('users'),
                        color: $getModuleColor('users'),
                        ability: ['update', 'users']
                    }
                    // {
                    //     title: 'menu.loginProviders',
                    //     path: 'settings-loginProviders',
                    //     ability: ['update', 'loginProviders']
                    // },
                ]
            }, {
                title: 'menu.baseData',
                path: 'baseData',
                icon: $getModuleIcon('baseData'),
                color: $getModuleColor('baseData'),
                children: [
                    {
                        title: 'menu.devices',
                        path: 'baseData-devices',
                        icon: $getModuleIcon('baseData'),
                        color: $getModuleColor('baseData'),
                        ability: ['viewAny', 'devices']
                    },
                    {
                        title: 'menu.costCenters',
                        path: 'baseData-costCenters',
                        icon: $getModuleIcon('baseData'),
                        color: $getModuleColor('baseData'),
                        ability: ['view', 'costCenters']
                    },
                    {
                        title: 'menu.mailTemplates',
                        path: 'settings-mailTemplates',
                        icon: $getModuleIcon('baseData'),
                        color: $getModuleColor('baseData'),
                        ability: ['viewAny', 'mailTemplates']
                    },
                    {
                        title: 'menu.categories',
                        path: 'baseData-categories',
                        icon: $getModuleIcon('baseData'),
                        color: $getModuleColor('baseData'),
                        ability: ['viewAny', 'categories']
                    },
                ]
            }
        ]
    },
    // {
    //     title: 'Abschnitt 2',
    //     menu: [
    //         {
    //             title: 'Element 1',
    //             initial: 'E1',
    //             path: '/',
    //
    //         },
    //         {
    //             title: 'Element 2',
    //             path: '/',
    //             initial: 'E2',
    //         },
    //     ]
    // }
];